import { useEffect } from 'react'
import { useLocation } from '@reach/router'

import Layout from 'components/Layout/Layout'
import { LayoutTextSection } from 'components/Layout/LayoutTextSection'
import Link from 'components/Link/Link'
import SEO from 'components/SEO/SEO'
import { navigateSSRSafe } from 'utils/navigateSSRSafe'

interface LocationState {
  fromForm?: boolean
}

const FormSuccessPage: React.FC = () => {
  const { state } = useLocation()
  const isFromForm = Boolean((state as LocationState | null)?.fromForm)

  useEffect(() => {
    const goToHomePage = async () => {
      await navigateSSRSafe('/', { replace: true })
    }

    if (!isFromForm) goToHomePage()
  }, [isFromForm])

  if (!isFromForm) return null

  return (
    <Layout>
      <SEO
        title="Formularz został przesłany"
        meta={[{ name: 'robots', content: 'noindex' }]}
      />
      <LayoutTextSection>
        <h1>Dziękujemy</h1>
        <p>Formularz został przesłany.</p>
        <p>
          Powrót do{' '}
          <Link to="/kontakt" replace>
            STRONY KONTAKTOWEJ
          </Link>
        </p>
      </LayoutTextSection>
    </Layout>
  )
}

export default FormSuccessPage
